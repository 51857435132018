import { ProjectItemList } from '@customink/project-service-react-components';
import '@/components/Cart.css';

export default function Cart() {
  return (
    <div className="pje-Cart" data-testid="Cart">
      <div className="pje-title">Your cart</div>

      <ProjectItemList groupByDesign responsive />
    </div>
  );
}
