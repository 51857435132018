import '@/components/CartOrderSummary.css';
import {
  AccordionOrderSummary,
  OrderSummary,
} from '@customink/project-service-react-components';
import { useMobileBreakpoint } from '@customink/pigment-react/lib/utils/';

export default function CartOrderSummary() {
  const isMobile = useMobileBreakpoint();
  return (
    <div className="pje-CartOrderSummary">
      {isMobile ? (
        <AccordionOrderSummary />
      ) : (
        <OrderSummary title="Order Summary" />
      )}
    </div>
  );
}
