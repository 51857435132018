import { ProfilesClient } from '@customink/profiles-sso-spa-client';

const createProfilesClient = () => {
  return new ProfilesClient({
    issuer: import.meta.env.PROJECT_SERVICE_AUTH_ISSUER,
    clientId: import.meta.env.PROJECT_SERVICE_AUTH_CLIENT_ID,
    redirectUri: window.location.origin,
  });
};

export const profilesClient = createProfilesClient();
