import { Component } from 'react';

import type { ErrorInfo, ReactNode } from 'react';

export interface ErrorBoundaryProps {
  children: ReactNode;
  fallback: JSX.Element;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

/*
  The Project Service prefers functional components to class components.
  The ErrorBoundary is the one exception to this rule,
  because only class components may be error boundaries.
*/

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // FIXME: add rollbar integration
    // console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    const { children, fallback } = this.props;
    return this.state.hasError ? fallback : children;
  }
}

export default ErrorBoundary;
