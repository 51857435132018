import type { ProjectServiceConfig } from '@customink/project-service-react-components';

export const config: ProjectServiceConfig = {
  catalogUrl: import.meta.env.PROJECT_SERVICE_CATALOG_URL,
  designsUrl: import.meta.env.PROJECT_SERVICE_DESIGNS_URL,
  imagesUrl: import.meta.env.PROJECT_SERVICE_IMAGES_URL,
  labProxyUrl: import.meta.env.PROJECT_SERVICE_LAB_PROXY_URL,
  pricesUrl: import.meta.env.PROJECT_SERVICE_PRICES_URL,
  productsUrl: import.meta.env.PROJECT_SERVICE_PRODUCTS_URL,
  profilesUrl: import.meta.env.PROJECT_SERVICE_PROFILES_URL,
  projectsUrl: import.meta.env.PROJECT_SERVICE_URL,
  quotesUrl: import.meta.env.PROJECT_SERVICE_QUOTES_URL,
  sizesUrl: import.meta.env.PROJECT_SERVICE_SIZES_URL,
  sizingUrl: import.meta.env.PROJECT_SERVICE_SIZING_URL,
  ciHostname: import.meta.env.PROJECT_SERVICE_CI_HOSTNAME,
};
