import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Cart from '@/components/Cart';
import OrderSummary from '@/components/CartOrderSummary';
import { ClipLoader } from 'react-spinners';

import {
  useProject,
  useConfigContext,
} from '@customink/project-service-react-components';
import { Container } from '@customink/pigment-react/lib/components/Layout';

import '@/components/App.css';

export default function App() {
  const hostName = useConfigContext().ciHostname;
  const { data: project, isLoading, isUninitialized } = useProject();
  return (
    <div className="pje-App" data-testid="App">
      <Header />
      <Container maxWidth="xl">
        {isUninitialized || (!isUninitialized && isLoading) ? (
          <div className="pje-App-mainLoader" data-testid="MainLoader">
            <ClipLoader color="#FA3C00" speedMultiplier={0.65} />
            <p>Loading your cart...</p>
          </div>
        ) : project?.items?.length !== undefined &&
          project?.items?.length > 0 ? (
          <div className="pje-App-contents" data-testid="AppContents">
            <section className="pje-section pje-section-order-summary">
              <OrderSummary />
            </section>
            <section className="pje-section pje-section-cart">
              <Cart />
            </section>
          </div>
        ) : (
          <div className="pje-App-emptyCart" data-testid="EmptyCart">
            <h2 className="pje-h2">Your shopping cart is empty...</h2>
            <h4 className="pje-h4">
              Please{' '}
              <a href={`${hostName}/accounts`}>retrieve one of your designs</a>{' '}
              and Checkout, start fresh in the{' '}
              <a href={`${hostName}/lab`}>Design Lab</a>, or return to our{' '}
              <a href={hostName}>home page</a>.
            </h4>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
}
