import ReactDOM from 'react-dom';
import PigmentThemeProvider from '@customink/pigment-react/lib/PigmentThemeProvider';
import { ProjectServiceApiProvider } from '@customink/project-service-react-components';
import App from '@/components/App';
import { config } from '@/config';
import ErrorBoundary from '@/components/ErrorBoundary';
import { AuthProvider } from '@/contexts/auth/AuthProvider';
import { useAuth } from '@/contexts/auth/AuthContext';

import '@/common.css';

const app = document.getElementById('root');

console.log('ENV Mode: ', import.meta.env.MODE);
console.log('PROD?: ', import.meta.env.PROD);
console.log('DEV?: ', import.meta.env.DEV);
console.log('ENV Base URL: ', import.meta.env.BASE_URL);
console.log('URL Config: ', config);

const EditorApp = () => {
  const { user, isLoading, tokens } = useAuth();
  const authContext = {
    isLoggedIn: user?.isLoggedIn ?? false,
    isLoading,
    tokens: {
      accessToken: tokens?.accessToken ?? '',
      idToken: tokens?.idToken ?? '',
    },
    user: {
      email: user?.email ?? '',
      fullName: user?.fullName ?? '',
    },
  };
  return (
    <ProjectServiceApiProvider
      config={config}
      // TODO: is it okay that we're using the App as the api fallback?
      // Probably not anymore now that we call `useProject` in it -kp
      fallback={<App />}
      auth={authContext}
    >
      <App />
    </ProjectServiceApiProvider>
  );
};

ReactDOM.render(
  <ErrorBoundary fallback={<div>Something went wrong.</div>}>
    <PigmentThemeProvider>
      <AuthProvider>
        <EditorApp />
      </AuthProvider>
    </PigmentThemeProvider>
  </ErrorBoundary>,
  app,
);
