import { createContext, useContext } from 'react';

export interface User {
  id: string;
  impersonated?: {
    originalEmail: string;
  };
  email: string;
  isInternal: boolean;
  emailDerivedName: string;
  fullName: string;
  isLoggedIn: boolean;
}

export interface Tokens {
  accessToken: string;
  idToken: string;
}
export interface AuthContextData {
  user: User | null;
  tokens: Tokens | null;
  isLoading: boolean;
}

export const AuthContext = createContext<AuthContextData | null>(null);

export function getTargetUserInfo(
  isImpersonated: boolean,
  userEmail: string,
  impersonatedEmail?: string | null,
) {
  if (isImpersonated) {
    return {
      email: impersonatedEmail ?? '',
      impersonated: {
        originalEmail: userEmail,
      },
    };
  }
  return {
    email: userEmail,
  };
}

export function useAuth() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error(
      `UserContext is null! Hook useUser must be used within a UserProvider`,
    );
  }

  return authContext;
}
