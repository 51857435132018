import type { AuthContextData } from '@/contexts/auth/AuthContext';
import { AuthContext, getTargetUserInfo } from '@/contexts/auth/AuthContext';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { profilesClient } from '@/contexts/auth/profilesClient';

const userContextDefaultData: AuthContextData = {
  user: null,
  tokens: null,
  isLoading: true,
};

export const AuthProvider: FC = ({ children }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const impersonate = searchParams.get('impersonate');

  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = useState<AuthContextData['user']>(null);
  const [tokens, setTokens] = useState<AuthContextData['tokens']>(null);

  const providerValue = useMemo<AuthContextData>(
    (): AuthContextData => ({
      ...userContextDefaultData,
      user,
      tokens,
      isLoading,
    }),
    [user, isLoading],
  );

  useEffect(() => {
    const initializeClient = async () => {
      profilesClient.subscribe(
        ({ isAuthenticated, userInfo, accessToken, idToken }) => {
          if (!isAuthenticated) {
            return;
          }

          const isImpersonate = Boolean(impersonate && userInfo?.internal);

          setUser({
            id: userInfo?.sub ?? '',
            isInternal: Boolean(userInfo?.internal),
            isLoggedIn: isAuthenticated,
            ...getTargetUserInfo(
              isImpersonate,
              userInfo?.email ?? '',
              impersonate,
            ),
            emailDerivedName: userInfo?.email?.split('@')[0] ?? '',
            fullName: userInfo?.name.trim().split('@')[0] ?? '', // the split on '@' patches a profiles bug where sometimes this field wrongly contains email instead of null.
          });

          setTokens({
            idToken: idToken ?? '',
            accessToken: accessToken ?? '',
          });
        },
      );

      await profilesClient.start();
      setIsLoading(false);
    };

    initializeClient();
  }, [profilesClient]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};
